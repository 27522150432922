// assets
import { IconVideo ,IconDeviceTv } from '@tabler/icons';

// constant
const icons = {
    IconVideo: IconVideo,
    IconDeviceTv: IconDeviceTv,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const health = {
    id: 'health',
    title: 'Health',
    type: 'group',
    children: [
        {
            id: 'health-video-list',
            title: 'Videos',
            type: 'item',
            url: '/health-video-list',
            icon: icons['IconVideo'],
            breadcrumbs: false,
            roles: ['admin'],
        },{
            id: 'health-live-list',
            title: 'Live',
            type: 'item',
            url: '/health-live-list',
            icon: icons['IconDeviceTv'],
            breadcrumbs: false,
            roles: ['admin'],
        }
    ]
};
