// assets
import { IconVideo, IconDeviceTv  } from '@tabler/icons';

// constant
const icons = {
    IconVideo: IconVideo,
    IconDeviceTv: IconDeviceTv
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const tv = {
    id: 'tv',
    title: 'TV',
    type: 'group',
    children: [
        {
            id: 'tv-video-list',
            title: 'Videos',
            type: 'item',
            url: '/tv-video-list',
            icon: icons['IconVideo'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'tv-live-list',
            title: 'Live',
            type: 'item',
            url: '/tv-live-list',
            icon: icons['IconDeviceTv'],
            breadcrumbs: false,
            roles: ['admin'],
        }
    ]
};
