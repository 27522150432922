import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
const ProfilePage = Loadable(lazy(() => import('../views/profile')));
// User Routes
const UserListPage = Loadable(lazy(() => import('../views/user')));
const UserCreatePage = Loadable(lazy(() => import('../views/user/create')));

// Category Routes
const CategoryListPage = Loadable(lazy(() => import('../views/category')));
const CategoryCreatePage = Loadable(lazy(() => import('../views/category/create')));

// Banner Routes
const BannerListPage = Loadable(lazy(() => import('../views/banner')));
const BannerCreatePage = Loadable(lazy(() => import('../views/banner/create')));

// Sub-Category Routes
const SubCategoryListPage = Loadable(lazy(() => import('../views/sub-category')));
const SubCategoryCreatePage = Loadable(lazy(() => import('../views/sub-category/create')));


// Group Routes
const GroupListPage = Loadable(lazy(() => import('../views/group')));
const GroupCreatePage = Loadable(lazy(() => import('../views/group/create')));

// Channel Routes
const ChannelListPage = Loadable(lazy(() => import('../views/channel')));
const ChannelCreatePage = Loadable(lazy(() => import('../views/channel/create')));

// Tv Video Routes
const TvVideoListPage = Loadable(lazy(() => import('../views/tv-video')));
const TvVideoCreatePage = Loadable(lazy(() => import('../views/tv-video/create')));
const TvLiveListPage = Loadable(lazy(() => import('../views/tv-live')));
const TvLiveCreatePage = Loadable(lazy(() => import('../views/tv-live/create')));

// Astro Video Routes
const AstroVideoListPage = Loadable(lazy(() => import('../views/astro-video')));
const AstroVideoCreatePage = Loadable(lazy(() => import('../views/astro-video/create')));
const AstroLiveListPage = Loadable(lazy(() => import('../views/astro-live')));
const AstroLiveCreatePage = Loadable(lazy(() => import('../views/astro-live/create')));

// Health Video Routes
const HealthVideoListPage = Loadable(lazy(() => import('../views/health-video')));
const HealthVideoCreatePage = Loadable(lazy(() => import('../views/health-video/create')));
const HealthLiveListPage = Loadable(lazy(() => import('../views/health-live')));
const HealthLiveCreatePage = Loadable(lazy(() => import('../views/health-live/create')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',
                '/sample-page',
                '/profile',
                '/user-list',
                '/user-create',
                '/user-edit/:id',
                
                '/banner-list',
                '/banner-create',
                '/banner-edit/:id',

                '/category-list',
                '/category-create',
                '/category-edit/:id',
                '/sub-category-list',
                '/sub-category-create',
                '/sub-category-edit/:id',
                '/group-list',
                '/group-create',
                '/group-edit/:id',

                '/channel-list',
                '/channel-create',
                '/channel-edit/:id',

                '/tv-video-list',
                '/tv-video-create',
                '/tv-video-edit/:id',
                '/tv-live-list',
                '/tv-live-create',
                '/tv-live-edit/:id',

                '/astro-video-list',
                '/astro-video-create',
                '/astro-video-edit/:id',
                '/astro-live-list',
                '/astro-live-create',
                '/astro-live-edit/:id',

                '/health-video-list',
                '/health-video-create',
                '/health-video-edit/:id',
                '/health-live-list',
                '/health-live-create',
                '/health-live-edit/:id',
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />

                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                        <Route path="/sample-page" component={SamplePage} />
                        <Route path="/profile" component={ProfilePage} />
                        <Route path="/user-list" component={UserListPage} />
                        <Route path="/user-create" component={UserCreatePage} />
                        <Route path="/user-edit/:id" component={UserCreatePage} />

                        <Route path="/banner-list" component={BannerListPage} />
                        <Route path="/banner-create" component={BannerCreatePage} />
                        <Route path="/banner-edit/:id" component={BannerCreatePage} />

                        <Route path="/category-list" component={CategoryListPage} />
                        <Route path="/category-create" component={CategoryCreatePage} />
                        <Route path="/category-edit/:id" component={CategoryCreatePage} />

                        <Route path="/sub-category-list" component={SubCategoryListPage} />
                        <Route path="/sub-category-create" component={SubCategoryCreatePage} />
                        <Route path="/sub-category-edit/:id" component={SubCategoryCreatePage} />
                        
                        <Route path="/group-list" component={GroupListPage} />
                        <Route path="/group-create" component={GroupCreatePage} />
                        <Route path="/group-edit/:id" component={GroupCreatePage} />

                        <Route path="/channel-list" component={ChannelListPage} />
                        <Route path="/channel-create" component={ChannelCreatePage} />
                        <Route path="/channel-edit/:id" component={ChannelCreatePage} />

                        <Route path="/tv-video-list" component={TvVideoListPage} />
                        <Route path="/tv-video-create" component={TvVideoCreatePage} />
                        <Route path="/tv-video-edit/:id" component={TvVideoCreatePage} />
                        <Route path="/tv-live-list" component={TvLiveListPage} />
                        <Route path="/tv-live-create" component={TvLiveCreatePage} />
                        <Route path="/tv-live-edit/:id" component={TvLiveCreatePage} />

                        <Route path="/astro-video-list" component={AstroVideoListPage} />
                        <Route path="/astro-video-create" component={AstroVideoCreatePage} />
                        <Route path="/astro-video-edit/:id" component={AstroVideoCreatePage} />
                        <Route path="/astro-live-list" component={AstroLiveListPage} />
                        <Route path="/astro-live-create" component={AstroLiveCreatePage} />
                        <Route path="/astro-live-edit/:id" component={AstroLiveCreatePage} />


                        <Route path="/health-video-list" component={HealthVideoListPage} />
                        <Route path="/health-video-create" component={HealthVideoCreatePage} />
                        <Route path="/health-video-edit/:id" component={HealthVideoCreatePage} />
                        <Route path="/health-live-list" component={HealthLiveListPage} />
                        <Route path="/health-live-create" component={HealthLiveCreatePage} />
                        <Route path="/health-live-edit/:id" component={HealthLiveCreatePage} />

                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
