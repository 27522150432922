// assets
import { IconDashboard, IconUser, IconCategory,IconCategory2,IconVideo, IconPhoto, IconDeviceTvOld  } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconUser: IconUser,
    IconCategory: IconCategory,
    IconCategory2:IconCategory2,
    IconVideo: IconVideo,
    IconPhoto: IconPhoto,
    IconDeviceTvOld: IconDeviceTvOld,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons['IconDashboard'],
            breadcrumbs: false,
            roles: ['dispatcher', 'admin'],
        },
        {
            id: 'user-list',
            title: 'Users',
            type: 'item',
            url: '/user-list',
            icon: icons['IconUser'],
            breadcrumbs: false,
            roles: ['admin'],
        },

        {
            id: 'banner-list',
            title: 'Banner',
            type: 'item',
            url: '/banner-list',
            icon: icons['IconPhoto'],
            breadcrumbs: false,
            roles: ['admin'],
        },

        {
            id: 'category-list',
            title: 'Category',
            type: 'item',
            url: '/category-list',
            icon: icons['IconCategory'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'sub-category-list',
            title: 'Sub-Category',
            type: 'item',
            url: '/sub-category-list',
            icon: icons['IconCategory2'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'group-list',
            title: 'Group',
            type: 'item',
            url: '/group-list',
            icon: icons['IconCategory2'],
            breadcrumbs: false,
            roles: ['admin'],
        },

        {
            id: 'channel-list',
            title: 'Channel',
            type: 'item',
            url: '/channel-list',
            icon: icons['IconDeviceTvOld'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        
    ]
};
