import { dashboard } from './dashboard';
import { tv } from './tv';
import { astro } from './astro';
import { health } from './health';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [
        dashboard,
        tv,
        astro,
        // health
    ]
};

export default menuItems;
